import Rellax from "rellax"

function isElementInViewport($elem) {
  if ($elem.offset() == null) return false

  // Get the scroll position of the page.
  var viewportTop = jQuery(window).scrollTop()
  var viewportBottom = viewportTop + jQuery(window).height()

  // Get the position of the element on the page.
  var elemTop = Math.round($elem.offset().top)
  var elemBottom = elemTop + $elem.height()

  return (
    elemTop < viewportBottom && elemBottom > viewportTop && $elem.is(":visible")
  )
}

$(document).ready(function () {
  animateElements()

  adjustGalleries()

  $(".search-icon").click(function () {
    $(this).toggleClass("shown")
    $(".search-form").toggleClass("shown")
  })

  $("a.target-burger").click(function (e) {
    $("a.target-burger, header#main-header").toggleClass("toggled")
    e.preventDefault()
  })

  $(".mobile-menu li.menu-item-has-children > a").click(function (e) {
    if ($(this).parent().hasClass("open")) return
    e.preventDefault()
    $("li.menu-item-has-children").removeClass("open")
    $(this).parent().addClass("open")
  })

  $("body").on("click", ".clickable-grant", function () {
    $(".grant-modal").removeClass("shown")
    let grant = $(this).data("grant")
    $("#" + grant).addClass("shown")
  })
  $("body").on("click", ".grant-close", function () {
    $(".grant-modal").removeClass("shown")
  })

  if (typeof String.prototype.trim !== "function") {
    String.prototype.trim = function () {
      return this.replace(/^\s+|\s+$/g, "")
    }
  }
  $("#cat").change(function () {
    var $cat = $(this).val()
    location.href = "/category/" + $cat
  })
  if ($("body").hasClass("category")) {
    var catName = $(".archive-title span").html().trim()
    $("#cat option").each(function () {
      if ($(this).text() == catName) {
        $(this).attr("selected", "selected")
      }
    })
  }
  $("#month").change(function () {
    var $month = $(this).val()
    location.href = $month
  })
  if ($("body").hasClass("date")) {
    var monthName = " " + $(".archive-title span").html().trim() + " "
    $("#month option").each(function () {
      if ($(this).text() == monthName) {
        $(this).attr("selected", "selected")
      }
    })
  }

  function getUrlVars() {
    var vars = [],
      hash
    var hashes = window.location.href
      .slice(window.location.href.indexOf("?") + 1)
      .split("&")
    for (var i = 0; i < hashes.length; i++) {
      hash = hashes[i].split("=")
      vars.push(hash[0])
      vars[hash[0]] = hash[1]
    }
    return vars
  }

  $("#case-studies-type-region-select").change(function () {
    var $region = $(this).val()
    var $type = "talent/"
    if ($("body").hasClass("term-mrg")) $type = "mrg/"
    if ($region != undefined && $region != "") {
      $region = "?region=" + $region.replace("#blog-filters", "")
    } else {
      $region = ""
    }
    location.href = "/case-studies/" + $type + $region + "#blog-filters"
  })

  /*
  $('#case-studies-type-focus-area-select').change(function() {
    var $focus_area = $(this).val();
    var $region = getUrlVars()["region"];
    var $type = 'talent/';
    if($('body').hasClass('term-mrg')) $type = 'mrg/';
    if($region!=undefined && $region!='') {
      $region = '?region=' + $region.replace('#blog-filters', '');
    } else {
      $region = '';
    }
    if($focus_area!=undefined && $focus_area!='') {
      if($region!='') {
        $focus_area = '&focus-area=' + $focus_area.replace('#blog-filters', '');
      } else {
        $focus_area = '?focus-area=' + $focus_area.replace('#blog-filters', '');
      }
    } else {
      $focus_area = '';
    }
    location.href = '/case-studies/' + $type + $region + $focus_area + '#blog-filters';
  });
  */

  $("#case-studies-region-select").change(function () {
    var $technology = getUrlVars()["technology"]
    var $focus_area = getUrlVars()["focus_area"]
    var $region = $(this).val()
    var $type = getUrlVars()["case-study-type"]
    if ($type != undefined && $type != "") {
      $type = "?case-study-type=" + $type.replace("#blog-filters", "")
    } else {
      $type = "?case-study-type=mrg"
    }
    if ($technology != undefined && $technology != "") {
      $technology = "&technology=" + $technology.replace("#blog-filters", "")
    } else {
      $technology = ""
    }
    if ($region != undefined && $region != "") {
      $region = "&region=" + $region
    } else {
      $region = ""
    }
    if ($focus_area != undefined && $focus_area != "") {
      $focus_area = "&focus_area=" + $focus_area.replace("#blog-filters", "")
    } else {
      $focus_area = ""
    }
    location.href =
      "/case-studies/" +
      $type +
      $region +
      $technology +
      $focus_area +
      "#blog-filters"
  })
  $("#case-studies-technology-select").change(function () {
    var $region = getUrlVars()["region"]
    var $technology = $(this).val()
    var $type = getUrlVars()["case-study-type"]
    if ($type != undefined && $type != "") {
      $type = "?case-study-type=" + $type.replace("#blog-filters", "")
    } else {
      $type = "?case-study-type=mrg"
    }
    if ($region != undefined && $region != "") {
      $region = "&region=" + $region.replace("#blog-filters", "")
    } else {
      $region = ""
    }
    if ($technology != undefined && $technology != "") {
      $technology = "&technology=" + $technology
    } else {
      $technology = ""
    }
    location.href =
      "/case-studies/" + $type + $technology + $region + "#blog-filters"
  })
  $("#case-studies-focus-area-select").change(function () {
    var $region = getUrlVars()["region"]
    var $focus_area = $(this).val()
    var $type = getUrlVars()["case-study-type"]
    if ($type != undefined && $type != "") {
      $type = "?case-study-type=" + $type.replace("#blog-filters", "")
    } else {
      $type = "?case-study-type=mrg"
    }
    if ($region != undefined && $region != "") {
      $region = "&region=" + $region.replace("#blog-filters", "")
    } else {
      $region = ""
    }
    if ($focus_area != undefined && $focus_area != "") {
      $focus_area = "&focus_area=" + $focus_area
    } else {
      $focus_area = ""
    }
    location.href =
      "/case-studies/" + $type + $focus_area + $region + "#blog-filters"
  })
  var $regiontext = ""
  var $technologytext = ""
  var $focusareatext = ""
  if ($("#case-studies-type-region-select").length > 0) {
    var $region = getUrlVars()["region"]
    if ($region != undefined && $region != "") {
      $region = $region.replace("#blog-filters", "")
      $("#case-studies-type-region-select option").each(function () {
        if ($(this).val() == $region) {
          $(this).attr("selected", "selected")
          $regiontext = $(this).text()
        }
      })
    }
  }
  if ($("#case-studies-region-select").length > 0) {
    var $region = getUrlVars()["region"]
    if ($region != undefined && $region != "") {
      $region = $region.replace("#blog-filters", "")
      $("#case-studies-region-select option").each(function () {
        if ($(this).val() == $region) {
          $(this).attr("selected", "selected")
          $regiontext = $(this).text()
        }
      })
    }
  }
  if ($("#case-studies-focus-area-select").length > 0) {
    var $focus_area = getUrlVars()["focus_area"]
    if ($focus_area != undefined && $focus_area != "") {
      $focus_area = $focus_area.replace("#blog-filters", "")
      $("#case-studies-focus-area-select option").each(function () {
        if ($(this).val() == $focus_area) {
          $(this).attr("selected", "selected")
          $focusareatext = $(this).text()
        }
      })
    }
  }
  if ($("#case-studies-technology-select").length > 0) {
    var $technology = getUrlVars()["technology"]
    if ($technology != undefined && $technology != "") {
      $technology = $technology.replace("#blog-filters", "")
      $("#case-studies-technology-select option").each(function () {
        if ($(this).val() == $technology) {
          $(this).attr("selected", "selected")
          $technologytext = $(this).text()
        }
      })
    }
  }
  var $type = getUrlVars()["case-study-type"]
  if ($type != undefined && $type != "") {
    $type = $type.replace("#blog-filters", "")
    $type = $type == "mrg" ? "Manufacturing Readiness Grants" : $type
    var $text = $type + " Case Studies"
    /*if($region!=undefined && $region!='' && $technology!=undefined && $technology!='') {
      $text += $regiontext + ', ' + $technologytext;
    } else if($region!=undefined && $region!='') {
      $text += $regiontext;
    } else if($technology!=undefined && $technology!='') {
      $text += $technologytext;
    }*/
    $(".archive-title span").html($text)
  }

  const el = document.querySelector(".blaze-slider")
  if (el != null) {
    new BlazeSlider(el, {
      all: {
        enableAutoplay: false,
        transitionDuration: 300,
        slidesToShow: 1,
      },
    })
  }

  //Video Block Play Button Functionality
  // if ($(".video-block-wrapper").length) {
  //   //button click
  //   $(".play-button").click(function () {
  //     let playButton = $(this)
  //     let video = $(this).siblings(".embed-responsive-item")

  //     if (video.get(0).paused) {
  //       //if video is paused
  //       playButton.addClass("off")
  //       video.attr("autoplay", "")
  //       video.attr("loop", "")
  //       video.get(0).play()
  //     } else {
  //       //if video is playing
  //     }
  //   })
  //   //video click
  //   $(".embed-responsive-item").on("click", function () {
  //     let $video = $(this).get(0)
  //     let $playButton = $(this).siblings(".play-button")

  //     if ($video.paused) {
  //       // video is paused //
  //       if ($playButton.hasClass("off")) {
  //         //if video is paused and play button is off
  //         $(this).attr("autoplay")
  //         $(this).attr("loop")
  //         $playButton.addClass("off")
  //         $video.play()
  //       } else {
  //         //if video is paused and play button is on
  //         $(this).attr("autoplay")
  //         $(this).attr("loop")
  //         $playButton.addClass("off")
  //         $video.play()
  //       }
  //     } else {
  //       // video is playing //
  //       console.log("getting here at least")
  //       if ($playButton.hasClass("off")) {
  //         //video is playing and play button is off
  //         $(this).removeAttr("autoplay")
  //         $(this).removeAttr("loop")
  //         $playButton.removeClass("off")
  //         $(this).get(0).pause()
  //       } else {
  //         //video is playing and play button is on
  //         $(this).removeAttr("autoplay")
  //         $(this).removeAttr("loop")
  //         $playButton.removeClass("off")
  //         $(this).get(0).pause()
  //       }
  //     }
  //   })
  // }

  if ($(".development-map-block-wrapper").length) {
    $(".accordion-item").on("click", function () {
      let childButton = $(this).find(".accordion-button")
      let parentContainer = $(this).closest(".col-md-7")
      let mapTarget = childButton.attr("map-toggle")
      let mapArray = $(".development-map-block-wrapper .indiana-map-wrapper")

      mapArray.each(function () {
        let currentMap = $(this)
        let mapIndex = currentMap.attr("development-map-index")

        if (parentContainer.siblings(".col-md-5").length) {
          if (!childButton.hasClass("collapsed")) {
            //if the button clicked is collapsed, find the corresponding image and toggle the show class
            toggleDevelopmentMapVisibility(
              currentMap,
              mapIndex === mapTarget,
              "d-none"
            )
          } else {
            //if the button clicked is open, continue showing the image that is already shown
            // let isDefaultImage =
            //   currentMap.children("img").attr("id") === "accordion-image-0"
            // toggleDevelopmentMapVisibility(currentMap, isDefaultImage, "d-none")
            return
          }
        }
      })
    })
  }

  function toggleDevelopmentMapVisibility(mapElement, condition, toggleClass) {
    if (condition) {
      mapElement.removeClass(toggleClass)
    } else {
      mapElement.addClass(toggleClass)
    }
  }

  ;(function () {
    /**
     * sends a custom event with a given type to google analytics
     *
     * @param {String} type - type of the event to send
     */
    function sendMapEvent(type) {
      //console.log("type = " + type);
      if (!readCookie("googleMapInteracted")) {
        dataLayer.push({
          event: "googlemaps",
          eventCategory: "Google Map",
          eventAction: type,
          eventLabel: "Interaction",
        })

        createCookie("googleMapInteracted", "true", 1)
      }
    }

    /**
     * bind map events to custom event handler for google analytics events
     * Please note: Google Map instance must be available under window.map
     * available map events: https://developers.google.com/maps/documentation/javascript/events
     */
    function bindMapEvents() {
      if (window.map) {
        var map = window.map

        map.addListener("dragend", function () {
          sendMapEvent("dragged")
        })

        map.addListener("zoom_changed", function () {
          sendMapEvent("zoomed")
        })

        map.addListener("maptypeid_changed", function () {
          sendMapEvent("map type changed")
        })

        map.addListener("click", function () {
          sendMapEvent("clicked")
        })

        map.addListener("rightclick", function () {
          sendMapEvent("right clicked")
        })

        map.addListener("dblclick", function () {
          sendMapEvent("double clicked")
        })
      }
    }

    /**
     * creates a cookie based on the given name, value and day count
     *
     * @param {String} name
     * @param {String} value
     * @param {Number} days
     */
    function createCookie(name, value, days) {
      if (days) {
        var date = new Date()

        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)

        var expires = "; expires=" + date.toGMTString()
      } else {
        var expires = ""
      }

      document.cookie = name + "=" + value + expires + "; path=/"
    }

    /**
     * reads a given cookie by name or return null
     *
     * @param {String} name
     */
    function readCookie(name) {
      var nameEQ = name + "="
      var ca = document.cookie.split(";")

      for (var i = 0; i < ca.length; i++) {
        var c = ca[i]

        while (c.charAt(0) == " ") {
          c = c.substring(1, c.length)
        }

        if (c.indexOf(nameEQ) == 0) {
          return c.substring(nameEQ.length, c.length)
        }
      }

      return null
    }

    // initially bind all map events
    bindMapEvents()
  })()
})

jQuery(window).on("load", function ($) {
  // setup parallax effect
  var rellax = new Rellax(".rellax", {
    center: true,
  })

  animateElements()
})

jQuery(window).scroll(function ($) {
  animateElements()
})

function adjustGalleries() {
  $(".wordpress-gallery.core-gallery").each(function () {
    $(this).find("figure.wp-block-image").unwrap()
  })
}

function animateElements() {
  // header
  var scrollTop = jQuery(window).scrollTop()
  if (scrollTop > 100) {
    jQuery("header#main-header").addClass("scrolled")
  } else {
    jQuery("header#main-header").removeClass("scrolled")
  }

  // svg backgrounds
  var k = 0
  $(".pillar-header").each(function () {
    if (
      !$(this).hasClass("shown") &&
      isElementInViewport($(".three-pillars-block-wrapper .circle"))
    ) {
      k++
      var elem = $(this)
      setTimeout(function () {
        elem.addClass("shown")
      }, 200 * k + 300)
    }
  })
  $(".pillar-content").each(function () {
    if (
      !$(this).hasClass("shown") &&
      isElementInViewport($(".three-pillars-block-wrapper .circle"))
    ) {
      k++
      var elem = $(this)
      setTimeout(function () {
        elem.addClass("shown")
      }, 200 * k + 500)
    }
  })

  var j = 0
  $("h1 strong, h2 strong, h3 strong, h4 strong, h5 strong, h6 strong").each(
    function () {
      if (!$(this).hasClass("shown") && isElementInViewport($(this))) {
        j++
        var elem = $(this)
        setTimeout(function () {
          elem.addClass("shown")
        }, 400 * j + 700)
      }
    }
  )
}
