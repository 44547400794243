/*********************************
 * ACF GOOGLE MAP FUNCTION
 *********************************/
 (function ($) {

    /**
     * initMap
     *
     * Renders a Google Map onto the selected jQuery element
     *
     * @date    22/10/19
     * @since   5.8.6
     *
     * @param   jQuery $el The jQuery element.
     * @return  object The map instance.
     */
    function initMap($el) {
  
      // Find marker elements within map.
      var $markers = $el.find('.marker');
      // Create gerenic map.
      var mapArgs = {
        zoom: $el.data('zoom') || 16,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
      };
      var map = new google.maps.Map($el[0], mapArgs);
      var infowindow = new google.maps.InfoWindow();
  
      // Add markers.
      map.markers = [];
      $markers.each(function () {
        initMarker($(this), map, infowindow);
      });
  
      // Center map based on markers.
      centerMap(map);
  
      // Return map instance.
      return map;
    }
  
    /**
     * initMarker
     *
     * Creates a marker for the given jQuery element and map.
     *
     * @date    22/10/19
     * @since   5.8.6
     *
     * @param   jQuery $el The jQuery element.
     * @param   object The map instance.
     * @return  object The marker instance.
     */
    function initMarker($marker, map, infowindow) {
      // Get position from marker.
      var infowindow = infowindow;
      var lat = $marker.data('lat');
      var lng = $marker.data('lng');
      var markerCategoryString = $marker.data('marker-categories');
      var grantTitle = $marker.data('grant-title');
      var executiveCommittee = $marker.data('executive-committee');
      if (typeof markerCategoryString !== 'undefined') {
        var markerCategoriesArray = markerCategoryString.split(',');
      } else {
        var markerCategoriesArray = [];
      }
      var latLng = {
        lat: parseFloat(lat),
        lng: parseFloat(lng)
      };
      // Create marker instance.
      var svg = `<svg id="MapMarker" width="25px" height="40px" fill="#ea679a" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 32 32" enable-background="new 0 0 32 32" xml:space="preserve"><path d="M16,0.5C9.229,0.5,3.74,5.989,3.74,12.76C3.74,19.531,14.367,31.5,16,31.5s12.26-11.969,12.26-18.74  C28.26,5.989,22.771,0.5,16,0.5z M16,17.139c-2.66,0-4.816-2.156-4.816-4.816c0-2.661,2.156-4.817,4.816-4.817  s4.816,2.156,4.816,4.817C20.816,14.982,18.66,17.139,16,17.139z"></path></svg>`;
      var marker = new google.maps.Marker({
        position: latLng,
        map: map,
        title: grantTitle,
        icon: 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(svg),
        categories: markerCategoriesArray,
        ec: executiveCommittee
      });
      // Append to reference for later use.
      map.markers.push(marker);
      // If marker contains HTML, add it to an infoWindow.
  
      // Show info window when marker is clicked.
      google.maps.event.addListener(marker, 'click', function () {
        if ($marker.html() && grantTitle !== '') {
          $('.infowindow-wrapper').removeClass('hidden');
          $('.infowindow-wrapper').addClass('shown');
          $('.infowindow-wrapper').html($marker.html());
        }
  
        $('.close-infowindow').on('click', function () {
          $('.infowindow-wrapper').removeClass('shown');
          $('.infowindow-wrapper').addClass('hidden');
        });
      });
  
    }
  
    /**
     * centerMap
     *
     * Centers the map showing all markers in view.
     *
     * @date    22/10/19
     * @since   5.8.6
     *
     * @param   object The map instance.
     * @return  void
     */
    function centerMap(map) {
      // Create map boundaries from all map markers.
      var bounds = new google.maps.LatLngBounds();
      map.markers.forEach(function (marker) {
        bounds.extend({
          lat: marker.position.lat(),
          lng: marker.position.lng()
        });
      });
      // Case: Single marker.
      if (map.markers.length == 1) {
        map.setCenter(bounds.getCenter());
        // Case: Multiple markers.
      } else {
        map.fitBounds(bounds);
      }
    }
  
  
    filterMarkers = function (map, selectedValue) {
      for (i = 0; i < map.markers.length; i++) {
        marker = map.markers[i];
        // If is same category or category not picked
        if (marker.categories.includes(selectedValue)) {
          marker.setVisible(true);
        } else if (selectedValue == '') {
          marker.setVisible(true);
        } else {
          marker.setVisible(false);
        }
      }
    }

    filterEC = function (map, clickedValue) {
      for (i = 0; i < map.markers.length; i++) {
        marker = map.markers[i];
        if (!clickedValue) {
          marker.setVisible(true);
        } else if(clickedValue && !marker.ec) {
          marker.setVisible(false);
        }
      }
    }
  
    // Render maps on page load.
    $(document).ready(function () {
      var map;
      $('.acf-map').each(function () {
        map = initMap($(this));
      });
      $('.map-filter-select').on('change', function (e) {
        var selectedValue = $(this).val();
        filterMarkers(map, selectedValue);
      });
      $('.map-filter-button').on('click', function (e) {
        $(this).toggleClass('clicked');
        var clickedValue = $(this).hasClass('clicked');
        filterEC(map, clickedValue);
      });
    });
  })(jQuery);
  /************************************
   * END ACF GOOGLE MAP FUNCTION
   *************************************/
