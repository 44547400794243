/*******************
 * Script Imports
 *******************/
import "popper.js";
import "bootstrap";
import "slick-slider";
import "./../node_modules/bootstrap-table/dist/bootstrap-table.js";
import "./../node_modules/rellax/rellax.min.js";

import "./vendor/vendor";
import "./utility/detect-ie";
import "./utility/is-touch-device";
import "./utility/acf-google-map";
import "./theme/scripts";
import "./theme/on-load.js";
import "./theme/on-scroll.js";
import "./theme/on-resize.js";

/*******************
 * Style Imports
 *******************/
//import "../scss/main.scss";